import React, { useState } from "react";
import "../../styles/Header.css";

const Header = () => {
  const [click, setClick] = useState(false);

  const handleClick = () => {
    setClick((prevClick) => !prevClick);
  };

  const closeMobileMenu = () => setClick(false);

  const goToHome = () => {
    window.location.href = "/";
  };

  const menuItems = [
    { title: "Home", link: "/#home", id: "home" },
    { title: "About", link: "/#about", id: "about" },
    { title: "Experience", link: "/#experience", id: "experience" },
    { title: "Projects", link: "/#projects", id: "projects" },
    { title: "Contact", link: "/#contact", id: "contact" },
    // Add more menu items here
  ];

  const goToSection = (sectionId) => {
    console.log(sectionId);
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    } else {
      goToHome();
    }
  };

  return (
    <header className="fixed w-full z-50 bg-white shadow-lg">
      <div className="flex items-center justify-between py-4 px-20 md:py-0 md:px-8">
        <div
          className="flex items-center text-black cursor-pointer hover:text-blue-500"
          onClick={goToHome}
        >
          <div className="header__logo-img-cont">
            <input
              type="image"
              src={require("../../assets/png/header-prof.png")}
              alt="logo image"
              className="header__logo-img"
            />
          </div>
          <span className="header__logo-sub">Bagus Nurhuda</span>
        </div>
        <div className="header__main">
          <ul className="header__links">
            {menuItems.map((item, index) => (
              <li className="header__link-wrapper" key={index}>
                <a
                  onClick={() => goToSection(item.id)}
                  className="header__link cursor-pointer"
                >
                  {item.title}
                </a>
              </li>
            ))}
          </ul>
          <div className="header__main-ham-menu-cont" onClick={handleClick}>
            <input
              type="image"
              src={require("../../assets/png/hamburger-menu.png")}
              alt="hamburger menu"
              className={`header__main-ham-menu ${click ? "d-none" : ""}`}
            />
            <img
              src={require("../../assets/png/ham-menu-close.png")}
              alt="hamburger menu close"
              className={`header__main-ham-menu-close ${click ? "" : "d-none"}`}
            />
          </div>
        </div>
      </div>
      <div
        className={`header__sm-menu ${click ? "header__sm-menu--active" : ""}`}
      >
        <div className="header__sm-menu-content">
          <ul className="header__sm-menu-links">
            {menuItems.map((item, index) => (
              <li
                className="header__sm-menu-link"
                key={index}
                onClick={closeMobileMenu}
              >
                <a
                  onClick={() => goToSection(item.id)}
                  className="header__link cursor-pointer"
                >
                  {item.title}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </header>
  );
};

export default Header;
