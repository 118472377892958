import React from "react";
import "../../styles/About.css";

export default function About() {
  const skills = [
    { title: "HTML" },
    { title: "CSS" },
    { title: "JavaScript" },
    { title: "ReactJS" },
    { title: "NodeJS" },
    { title: "Java" },
    { title: "Golang" },
    { title: "Git" },
    { title: "Docker" },
    { title: "Google Cloud" },
    { title: "Python (Jupyter Notebook)" },
    { title: "SQL" },
    { title: "GraphQL" },
    { title: "Redis" },
  ];
  return (
    <section id="about" className="about sec-pad">
      <div className="main-container">
        <h2 className="heading heading-sec heading-sec__mb-med">
          <span className="heading-sec__main font-bold">About Me</span>
          <span className="heading-sec__sub">
            In here, you will find more information about me, my skills, and
            what I do mostly in my recent life especially about technology
          </span>
        </h2>
        <div className="about__content">
          <div className="about__content-main">
            <h3 className="about__content-title">Get in touch with me!</h3>
            <div className="about__content-details">
              <p className="about__content-details-para">
                I'm <strong> Bagus Nurhuda </strong>, a recent graduate from the
                University of Indonesia with a specialization in computer
                engineering. My academic journey has equipped me with a strong
                foundation in both frontend and backend software development,
                and I'm particularly enthusiastic about exploring the realms of
                cloud architecture.
              </p>
              <p className="about__content-details-para">
                I'm interested in <strong>software development</strong>{" "}
                including <strong>frontend</strong> and <strong>backend</strong>{" "}
                . Sometimes I also learning about{" "}
                <strong> cloud computing </strong>
                and I'm ready to be parts of several companies. Feel free to
                <strong> contact</strong> me here.
              </p>
            </div>
            <a href="#contact" className="btn btn--med btn--theme dynamicBgClr">
              Contact
            </a>
          </div>
          <div className="about__content-skills">
            <h3 className="about__content-title">My Skills</h3>
            <div className="skills">
              {skills.map((item, index) => (
                <div className="skills__skill">{item.title}</div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
