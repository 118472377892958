import React from "react";
// import "../../styles/Project.css";
import projects from "../../data/project.js";

const Home_Project = () => {
  return (
    <section id="projects" className="about sec-pad">
      <div className="main-container">
        <h2 className="heading heading-sec heading-sec__mb-bg">
          <span className="heading-sec__main font-bold">Projects</span>
          <span className="heading-sec__sub">
            Here is a list of my applications that I have been working on,
            either in a team or individually. Check them out now!
          </span>
        </h2>

        <div className="projects__content">
          {projects.map((project, index) => (
            <div className="projects__row" key={index}>
              <div className="projects__row-img-cont">
                <img
                  src={project.image}
                  alt="Software Screenshot"
                  className="projects__row-img"
                  loading="lazy"
                />
              </div>
              <div className="projects__row-content">
                <h3 className="projects__row-content-title">{project.title}</h3>
                <p className="projects__row-content-desc">
                  {project.description}
                </p>
                <a
                  href={project.link}
                  onClick={project.onClick}
                  className="btn btn--med btn--theme dynamicBgClr btn-11"
                >
                  See
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Home_Project;
