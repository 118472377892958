import React from "react";
import "../../styles/Footer.css";

const socialLinks = [
  {
    href: "https://www.linkedin.com/in/bagusnurhuda/",
    iconSrc: require("../../assets/png/linkedin-ico.png"),
    alt: "LinkedIn",
  },
  {
    href: "https://github.com/BagusNurhudaUI",
    iconSrc: require("../../assets/png/github-ico.png"),
    alt: "GitHub",
  },
  {
    href: "https://www.youtube.com/channel/UCjjZ7G9Miy074O_q2xu6ZnA",
    iconSrc: require("../../assets/png/yt-ico.png"),
    alt: "YouTube",
  },
  {
    href: "https://instagram.com/bagusnurhuda",
    iconSrc: require("../../assets/png/insta-ico.png"),
    alt: "Instagram",
  },
];

const Footer = () => (
  <footer className="main-footer">
    <div className="main-container">
      <div className="main-footer__upper">
        <div className="main-footer__row main-footer__row-1">
          <h2 className="heading heading-sm main-footer__heading-sm">
            <span>Social</span>
          </h2>
          <div className="main-footer__social-cont flex">
            {socialLinks.map((link, index) => (
              <a key={index} target="_blank" rel="noreferrer" href={link.href}>
                <img
                  className={`main-footer__icon ${
                    index === socialLinks.length - 1
                      ? "main-footer__icon--mr-none"
                      : ""
                  }`}
                  src={link.iconSrc}
                  alt={link.alt}
                />
              </a>
            ))}
          </div>
        </div>
        <div className="main-footer__row main-footer__row-2">
          <h4 className="heading heading-sm text-lt">Bagus Nurhuda</h4>
          <p className="main-footer__short-desc">
            A learner who focuses on developing themselves can often become good
            or even better.
          </p>
        </div>
      </div>

      <div className="main-footer__lower">
        &copy; 2022&nbsp;
        <a
          rel="noreferrer"
          target="_blank"
          href="https://instagram.com/bagusnurhuda"
          className="no-underline font-bold"
        >
          Bagus Nurhuda.
        </a>
        &nbsp; All Rights Reserved.
      </div>
    </div>
  </footer>
);

export default Footer;
