import React, { useState, useEffect } from "react";
import axios from "axios";
import "../../styles/Home.css";

function Home() {
  return (
    <section className="home-hero" id="home">
      <div className="home-hero__content">
        <div className="home-hero__content1">
          <h1 className="heading-primary">Hello, My name is Bagus Nurhuda</h1>
          <div className="home-hero__info">
            <p className="text-primary">
              I'm a fresh graduate <strong> Computer Enginering</strong> from
              <strong> University of Indonesia.</strong>
            </p>
          </div>
          <div className="home-hero__cta">
            <a
              href="https://drive.google.com/file/d/19D7LqtDR9Mcr7C0e2NrN1R4vPmEQsD-D/view?usp=sharing"
              target="_blank"
              className="btn btn--bg"
            >
              Download My CV
            </a>
          </div>
        </div>
        <div className="home-hero__content2">
          <div className="home-hero__content_image">
            <img
              src={require("../../assets/png/prof.webp")}
              alt="gambar-main"
            />
            {/* <img src='https://storage.googleapis.com/dicoding-1/prof.png' alt="gambar-main" width={300}/> */}
          </div>
        </div>
      </div>

      <div className="home-hero__mouse-scroll-cont">
        <div className="mouse"></div>
      </div>
    </section>
  );
}

export default Home;
