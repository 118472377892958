import experienceImg1 from "../assets/png/kitabisa.webp";
import experienceImg2 from "../assets/png/telkomsel.webp";
import experienceImg3 from "../assets/png/bangkit.png";
import experienceImg4 from "../assets/png/up2m.png";

const experiences = [
  {
    title: "UP2M DTE FTUI",
    role: "Project Assistant - Data Engineer",
    description:
      "UP2M FTUI stands for Unit Pengelola Program Pendidikan Profesi Manajemen (Management Professional Education Program Management Unit) at the Faculty of Engineering, University of Indonesia. The unit offers professional education programs in management, designed for individuals who want to advance their careers in various industries.",
    image: experienceImg4,
  },
  {
    title: "Telkomsel",
    role: "Cloud Management Intern",
    description:
      "Telkomsel is the largest cellular network operator in Indonesia, providing mobile telecommunication services and digital products to millions of customers across the country. The company was established in 1995 and has since grown to become a market leader, offering a wide range of services, including voice calls, SMS, internet data, mobile financial services, and digital lifestyle products.",
    image: experienceImg2,
  },
  {
    title: "Kitabisa",
    role: "Backend Developer Project Intern",
    description:
      "Kitabisa.com is a popular crowdfunding platform in Indonesia that helps individuals and organizations raise funds for various causes such as disaster relief, healthcare, education, and social welfare. The platform offers user-friendly features to create campaigns and attract donors, and has facilitated thousands of successful campaigns, raising millions of dollars for various causes.",
    image: experienceImg1,
  },

  {
    title: "Bangkit Academy",
    role: "Cloud Computing Cohort",
    description:
      "Bangkit Academy is an online learning platform in Indonesia that offers courses in various fields of technology. The platform was launched by Google, Tokopedia, Gojek, and Traveloka in collaboration with the Indonesian government to help bridge the digital skills gap in the country and support the development of local tech talent.",
    image: experienceImg3,
  },
];

export default experiences;
