import React from "react";

export default function PageNotFound() {
  return (
    <section className="h-screen min-h-[80rem] max-h-[120rem] relative flex items-center justify-center">
      <div>
        <h2 className="text-3xl uppercase tracking-wide text-center">
          <img
            src={require("../assets/png/not-found.png")}
            alt="gambar-main"
            loading="lazy"
            className="max-w-xs mx-auto my-4"
          />
          <span className="text-black font-bold">404 page not found</span>
          <span className="text-black block text-2xl mt-2 font-bold">
            The page you are trying to access does not exist.
          </span>
        </h2>
      </div>
    </section>
  );
}
