import React from "react";
import experiences from "../../data/experience";
import "../../styles/Experience.css";

export default function Experience() {
  const alertHandle = function () {
    alert("This feature is still on deployment");
  };

  return (
    <section id="experience" className="projects sec-pad">
      <div className="main-container">
        <h2 className="heading heading-sec heading-sec__mb-bg">
          <span className="heading-sec__main font-bold">Experience</span>
          <span className="heading-sec__sub">
            Here is a list of my experience that I have achieved from last 2
            years. Check it now!
          </span>
        </h2>

        <div className="experiences__content">
          {experiences.map((experience, index) => (
            <div className="experiences__row" key={index}>
              <div className="experiences__row-img-cont flex-col">
                <img
                  src={experience.image}
                  alt="Software Screenshot"
                  className="experiences__row-img"
                  loading="lazy"
                />
              </div>
              <div className="experiences__row-content">
                <h3 className="experiences__row-content-title">
                  {experience.title}
                </h3>
                <h3 className="experiences__row-content-subtitle">
                  {experience.role}
                </h3>
                <p className="experiences__row-content-desc">
                  {experience.description}
                </p>
                <a
                  // href="/"
                  onClick={alertHandle}
                  id={`exp${index}`}
                  className="btn btn--med btn--theme dynamicBgClr btn-11"
                >
                  More
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
