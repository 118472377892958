import gmeImage from "../assets/png/gme.png";
import kasbaikImage from "../assets/png/kasbaik-removebg.png";
import akurImage from "../assets/png/akur-removebg.png";

const projects = [
  {
    title: "GME Monitoring Assets",
    description:
      "GME Monitoring is a monitoring application that helps companies track and manage their assets. This app has several features that are useful to make a report from assets.",
    image: gmeImage,
    onClick: () => {
      alert("This feature is still on deployment");
    },
  },
  {
    title: "Kasbaik by Kitabisa",
    description:
      "Kasbaik by Kitabisa is an application that helps Kitabisa Company to downstream their donation from people to someone that need to help. This application also built with Machine Learning to improve in data processing such as KTP, and and other data in form.",
    image: kasbaikImage,
    link: "/kasbaik",
  },
  {
    title: "Audit Kurir",
    description:
      "Audit Kurir or akur is an Android application that can divide all tracking information from seller with many orders and want to separate their shipment delivery.",
    image: akurImage,
    link: "/akur",
  },
];

export default projects;
