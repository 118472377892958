import "./App.css";
import Akur from "./pages/Akur";
import Kasbaik from "./pages/Kasbaik";
import Home from "./pages/Home";
import Header from "./components/mainpages/Header";
import Footer from "./components/mainpages/Footer";
import PageNotFound from "./pages/PageNotFound";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

function App() {
  return (
    <div>
      <noscript>"bagus Nurhuda"</noscript>
      <Header />
      <Router>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/akur" element={<Akur />} />
          <Route exact path="/kasbaik" element={<Kasbaik />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Router>
      <Footer />
    </div>
  );
}

export default App;
