import React, { useState, useEffect } from "react";
import MainPage from "../components/mainpages/Home-hero";
import About from "../components/mainpages/Home-about";
import Projects from "../components/mainpages/Home-project";
// import Gallery from "../components/mainpages/Home-gallery";
import Contact from "../components/mainpages/Home-contact";
import Experience from "../components/mainpages/Home-experience";
export default function Home() {
  return (
    <div>
      <MainPage />
      <About />
      <Experience />
      <Projects />
      {/* <Gallery />  */}
      <Contact />
    </div>
  );
}
